import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro';
import Pertama from './Pertama';

const AppContainer = styled.div`
  ${tw`
    h-full
    w-full
    flex
    flex-col
    bg-pink-200
  `}
`;

export default function App() {
  return (
    <AppContainer>
      <Pertama />
    </AppContainer>
  )
}
