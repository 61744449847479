import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import axios from 'axios'

const options = [
    { id: 1, name: 'Mauuu', inStock: true },
    { id: 0, name: 'Ngga Dulu', inStock: true },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function getCurrentDate(separator = '') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}

const Content = (props) => {
    const [name, setName] = useState('');
    const [step, setStep] = useState(1);
    const [mem, setMem] = useState(options[2])
    const saveName = () => {
        localStorage.setItem('nama', name);
        props.setName(name)
        setStep(2);
    }

    const saveOpsi = () => {
        localStorage.setItem('opsi', mem['name']);
        setStep(3);
    }
    if (step === 1) {
        return (
            <div className="space-y-6">
                <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                        Nama kamu siapa?
                    </label>
                    <div className="mt-1">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            onChange={e => setName(e.target.value)}
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-pink-500 focus:outline-none focus:ring-pink-500 sm:text-sm"
                        />
                    </div>
                </div>

                <div>
                    <button
                        type='button'
                        onClick={saveName}
                        className="flex w-full justify-center rounded-md border border-transparent bg-pink-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                    >
                        Lanjut
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>

                    </button>
                </div>
            </div>
        )
    } else if (step === 2) {
        return (
            <>
                <label htmlFor="iya" className="block text-sm font-medium text-gray-700">
                    Udah tau kan pilihan kamu?
                </label>
                <RadioGroup name="iya" value={mem} onChange={setMem} className="mt-2">
                    <RadioGroup.Label className="sr-only"> Choose a memory option </RadioGroup.Label>
                    <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                        {options.map((option) => (
                            <RadioGroup.Option
                                key={option.name}
                                value={option}
                                className={({ active, checked }) =>
                                    classNames(
                                        option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed',
                                        active ? 'ring-2 ring-offset-2 ring-pink-500' : '',
                                        checked
                                            ? 'bg-pink-600 border-transparent text-white hover:bg-pink-700'
                                            : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50',
                                        'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1'
                                    )
                                }
                                disabled={!option.inStock}
                            >
                                <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                            </RadioGroup.Option>
                        ))}
                    </div>
                </RadioGroup>
                <button
                    type='button'
                    onClick={saveOpsi}
                    className="mt-4 flex w-full justify-center rounded-md border border-transparent bg-pink-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-pink-500 focus:ring-offset-2"
                >
                    Lanjut
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                    </svg>

                </button>
            </>
        )
    } else if (step === 3) {
        if (mem.id === 1) {
            localStorage.setItem('date', getCurrentDate('-'));
            axios.post("https://api.telegram.org/bot5000962213:AAHpscfaO2CdiChddU-hM8S60ZXSL2juQFI/sendMessage", {
                "chat_id": 981113265,
                "text": `## INFO INFO \n${localStorage.getItem('nama')} menerima mu menjadi pacarnya! \nsekarang tanggal ${localStorage.getItem('date')}`,
                "parse_mode": "markdown"
            })
            return (
                <>
                    <p className='font-mono'>Yay! 🎉 🎉 Ini jadi salah satu hari terbaik bagiku! Aku senang mendengar ini!</p>
                    <br />
                    <p className='font-mono'>Sekarang tanggal <b>{localStorage.getItem('date')}</b> 👩‍❤️‍👨</p>
                    <br />
                    <p className='font-mono'>Okay my sweetie! kita lanjut ke WhatsApp aja ya?</p>

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="bg-white px-2 text-gray-500">Lanjut ke WhatsApp</span>
                            </div>
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-3">
                            <div>
                                <a href="https://wa.me/6285755773985?text=Hi!%20Aku%20mau!" className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                                    <span className="sr-only">Lanjut ke WhatsApp</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"> <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" /> </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else {
            axios.post("https://api.telegram.org/bot5000962213:AAHpscfaO2CdiChddU-hM8S60ZXSL2juQFI/sendMessage", {
                "chat_id": 981113265,
                "text": `## INFO INFO \n${localStorage.getItem('nama')} belum siap menerima mu menjadi pacarnya :(((`,
                "parse_mode": "markdown"
            })
            return (
                <>
                    <p className='font-mono'>Aku menghargai pilihanmu 😔 😔 😔 mungkin aku butuh waktu untuk berbenah.</p>
                    <br />
                    <p className='font-mono'>Aku berharap hubungan kita tetap baik-baik saja, sama seperti sebelumnya. Jangan ada permusuhan ya :)</p>
                    <br />
                    <p className='font-mono'>Aku mau tau alasannya, kamu kirim ke whatsapp aja ya!</p>

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="bg-white px-2 text-gray-500">Lanjut ke WhatsApp</span>
                            </div>
                        </div>

                        <div className="mt-6 grid grid-cols-1 gap-3">
                            <div>
                                <a href="https://wa.me/6285755773985?text=Hi!%20Aku%20mau!" className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50">
                                    <span className="sr-only">Lanjut ke WhatsApp</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"> <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" /> </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }
}


export default function Pertama() {
    const [name, setName] = useState(null);
    return (
        <>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">{name ? `Halo! ${name} Cantik 🫶🏼` : 'Selamat Datang!'}</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <Content setName={setName} />
                    </div>
                </div>
            </div>
        </>
    )
}
